<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      ><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->ลูกค้ารับเล่มไปแล้ว</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center" dense class="mt-1">
        <v-col cols="5">
          <v-text-field
            v-model="item.receiver_name"
            label="ชื่อผู้มารับเอกสาร"
            outlined
            dense
            background-color="white"
            :error-messages="$v.item.receiver_name.$error ? $error_text : ''"
          />
        </v-col>
        <v-col cols="5"></v-col>
        <v-col cols="5">
          <DatePicker
            v-model="item.pick_up_date"
            label="วันที่มารับ"
            dense
            background-color="white"
            :error-messages="$v.item.pick_up_date.$error ? $error_text : ''"
            @onChange="val => (form.pick_up_date = val)"
          />
          <v-text-field
            v-model="item.parcel_delivery"
            label="จัดส่งพัสดุ"
            outlined
            dense
            background-color="white"
            hide-details
            :error-messages="$v.item.parcel_delivery.$error ? $error_text : ''"
          />
        </v-col>
        <v-col cols="5">
          <time-picker
            :value="item.pick_up_time"
            lable="เวลาที่มารับ"
            outlined
            dense
            background-color="white"
            :error-messages="$v.item.pick_up_time.$error ? $error_text : ''"
            @onChange="val => (form.pick_up_time = val)"
          />
          <v-text-field
            v-model="item.delivery_number"
            label="เลขพัสดุ"
            outlined
            dense
            background-color="white"
            hide-details
            :error-messages="$v.item.delivery_number.$error ? $error_text : ''"
          />
        </v-col>
        <v-col cols="5" hide-details></v-col>
        <v-col cols="10">
          <v-textarea
            v-model="item.note"
            label="โน๊ต"
            outlined
            dense
            background-color="white"
            hide-details
            :error-messages="$v.item.note.$error ? $error_text : ''"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ปิด</v-btn
      >
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึก</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';

import * as moment from 'moment';

export default {
  components: {
    DatePicker,
    TimePicker
  },
  props: {
    item: {
      type: Object,
      default: null
    }
    // oldData: {
    //   type: Object,
    //   default: null,
    // }
  },
  validations: {
    item: {
      receiver_name: { required },
      pick_up_date: { required },
      pick_up_time: { required },
      parcel_delivery: { required },
      delivery_number: { required },
      note: { required }
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    onChangeDate(val) {
      this.form.pick_up_date = moment(val).format('YYYY-MM-DD');
    },
    onChangeTime(val) {
      this.form.pick_up_time = moment(val).format('HH:mm');
    },
    close() {
      this.$emit('onClose');
    },

    async save() {
      let postData = {
        process_id: this.item._id,
        receiver_name: this.item.receiver_name,
        pick_up_date: this.item.pick_up_date,
        pick_up_time: this.item.pick_up_time,
        parcel_delivery: this.item.parcel_delivery,
        delivery_number: this.item.delivery_number,
        note: this.item.note
      };
      let body = {
        token: this.$jwt.sign(postData, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/record_recieve_book`, body)
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });

      this.close();
    }
  },
  computed: {
    isUpdate() {
      return this.item;
    }
  }
};
</script>
