<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">
      งานเสร็จสมบูรณ์ - {{ item.car_plate + ' ' + item.province }}
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center" dense>
        <v-col cols="5">
          <v-text-field
            v-model="item.customer_phone_number"
            label="เบอร์โทรลูกค้า"
            outlined
            dense
            background-color="white"
            disabled
            class="mt-2"
          />
          <v-text-field
            v-model="item.tax_data.transportation_product"
            label="งานขนส่ง"
            outlined
            dense
            background-color="white"
            disabled
          />
          <v-select
            v-model="item.is_original_document"
            :items="list.is_original_document"
            label="เอกสาร"
            outlined
            dense
            background-color="white"
            disabled
            item-value="value"
            item-text="name"
          />
        </v-col>
        <v-col cols="5"></v-col>
        <v-col cols="5">
          <v-text-field
            v-model="item.parcel_delivery"
            label="จัดส่งพัสดุ"
            outlined
            dense
            background-color="white"
            disabled
          />
        </v-col>
        <v-col cols="5" class="text-right">
          <!-- <div class="d-flex ml-15">
            <span class="mr-5">ส่ง SMS แจ้งเลขพัสดุ</span>
            <v-btn color="primary" small>ส่งแล้ว</v-btn>
          </div> -->
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="item.delivery_number"
            label="เลขพัสดุ"
            outlined
            dense
            background-color="white"
            :error-messages="$v.item.delivery_number.$error ? $error_text : ''"
          />
          <v-text-field
            v-model="item.margin_minus"
            label="เก็บเพิ่ม"
            outlined
            dense
            background-color="white"
            disabled
          />
          <v-text-field
            v-model="item.margin_plus"
            label="คืนเงิน"
            outlined
            dense
            background-color="white"
            disabled
          />
          <v-text-field
            v-model="item.number"
            label="เลขที่เอกสาร"
            outlined
            dense
            background-color="white"
            disabled
          />
        </v-col>
        <v-col cols="5"></v-col>
        <v-col cols="10">
          <v-textarea
            v-model="item.note"
            label="โน๊ต"
            outlined
            dense
            background-color="white"
            :error-messages="$v.item.note.$error ? $error_text : ''"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ปิด
      </v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
      >
        ลูกค้ารับเล่มแล้ว
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';

import * as moment from 'moment';
export default {
  components: {
    DatePicker,
    TimePicker
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  validations: {
    item: {
      delivery_number: { required },
      note: { required }
    }
  },
  data: () => ({
    loading: false,
    list: {
      is_original_document: [
        { value: true, name: 'ตัวจริง' },
        { value: false, name: 'สำเนา' }
      ]
    }
  }),
  methods: {
    close() {
      this.$emit('onClose');
    },
    async save() {
      this.loading = true;
      this.item.pick_up_date = moment().format('YYYY-MM-DD');
      this.item.pick_up_time = moment().format('HH:mm');
      let payload = {
        process_id: this.item._id,
        pick_up_date: this.item.pick_up_date,
        pick_up_time: this.item.pick_up_time,
        delivery_number: this.item.delivery_number,
        note: this.item.note
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/record_tax_parcel_book`, body)
        .then(res => {
          this.$emit('onClose');
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
      this.close();
    }
  },
  computed: {
    isUpdate() {
      return this.item;
    }
  }
};
</script>
