<template>
  <v-card class="py-3">
    <v-card-title class="justify-center text-h4 primary--text"
      ><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->สรุปรถที่จะไปต่อภาษี</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row v-if="item">
        <v-col cols="10" offset="1" class="pt-8 pb-0">
          <v-text-field
            v-model="item.tax_renewal_round"
            label="รอบงานที่"
            outlined
            dense
            background-color="white"
            disabled
          />
          <v-text-field
            :value="item.one_year_extension_tax"
            label="รถที่ต่อแค่ 1 ปี มี"
            outlined
            dense
            background-color="white"
            disabled
            suffix="คัน"
          />
          <v-text-field
            :value="item.car_plate_one_year.join()"
            label="เลขทะเบียน (ต่อแค่ 1 ปี)"
            outlined
            dense
            background-color="white"
            disabled
          />
          <v-text-field
            :value="item.car_type2_count"
            label="รย. 2 มี"
            outlined
            dense
            background-color="white"
            disabled
            suffix="คัน"
          />
          <v-text-field
            :value="item.car_plate_car_type2.join()"
            label="เลขทะเบียน (รถ รย.2)"
            outlined
            dense
            background-color="white"
            disabled
          />
          <v-text-field
            :value="item.total_car"
            label="จำนวนรถต่อภาษีทั้งหมด"
            outlined
            dense
            background-color="white"
            disabled
            suffix="คัน"
          />
          <v-text-field
            :value="item.total_extension_fee | numeral('0,0.00')"
            label="ราคารวม"
            outlined
            dense
            background-color="white"
            disabled
            suffix="บาท"
          />
        </v-col>
        <v-col cols="10" offset="1" class="mt-0 pt-0">
          <v-text-field
            :value="item.total_transportation_bribe | numeral('0,0.00')"
            label="ค่าช่องขนส่ง / บาท"
            outlined
            dense
            background-color="white"
            disabled
            suffix="บาท"
          />
        </v-col>
        <v-col cols="1">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <v-sheet class="pa-3">info</v-sheet>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="onClose()"
        >ปิด</v-btn
      >
      <v-btn
        v-if="!saved"
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึกข้อมูล</v-btn
      >
      <v-btn
        v-else
        class="px-10 mr-15"
        color="success"
        @click="download()"
        :loading="loading"
        :disabled="loading"
        >ดาวน์โหลดใบรวมภาษี <v-icon right>mdi-printer</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    processArray: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      loading: false,
      saved: false
    };
  },
  methods: {
    onClose() {
      this.saved = false;
      if (!this.saved) {
        this.$emit('onClose');
        this.$router
          .push({ name: 'follow-tax', params: { tab: 0 } })
          .catch(() => {});
      } else {
        this.$emit('onClose');
        this.$router
          .push({ name: 'follow-tax', params: { tab: 1 } })
          .catch(() => {});
      }
      this.$emit('reloadParent');
      //window.location.reload();
    },
    async save() {
      this.saved = false;
      this.loading = true;
      let payload = {
        branch_id: this.$store.state.selected_branch._id,
        process_array: this.processArray,
        tax_renewal_round: this.item.tax_renewal_round
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/save_renewal_tax`, body)
        .then(res => {
          this.$emit('onSave');
          this.saved = true;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });

      this.loading = false;
    },

    async download() {
      this.loading = true;
      let payload = {
        branch_id: this.$store.state.selected_branch._id,
        process_array: this.processArray,
        tax_renewal_round: this.item.tax_renewal_round
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/process/download_car_renewal_tax_summary`,
        responseType: 'blob',
        data: body
      })
        .then(response => {
          this.forceFileDownload(
            response,
            'ใบรวมภาษี-' + this.item.tax_renewal_round + '.xlsx'
          );
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
      this.onClose();
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    }
  },
  computed: {
    isUpdate() {
      return this.item;
    }
  }
};
</script>
