<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      :key="selected.length"
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:footer>
        <table-pagination-custom
          :page.sync="filter.page"
          :itemsPerPage.sync="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as moment from 'moment';
export default {
  props: ['search', 'carTypeId', 'updateList'],
  data: () => ({
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    headers: [
      { text: 'ลำดับ', value: '_id', filterable: false },
      { text: 'วันที่สร้างบิล', value: 'date', filterable: false },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จัวหวัด', value: 'province', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      { text: 'หมายเหตุ', value: 'reason_on_fail', filterable: false },
    ],

    isCar: true,
    filter: {
      branch_id: null,
      tracking_type: 0,
      tracking_status: 4,
      tax_renewal_round: '',
      car_type_filter: 0,
      item_per_page: 10,
      page: 1,
      start_with: '',
    },
  }),
  mounted() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_process`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
  },
  watch: {
    search(nv, ov) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (nv != ov) {
          this.filter.start_with = nv;
          this.getData();
        }
      }, 1000);
    },
    carTypeId(nv, ov) {
      if (nv != ov) {
        this.filter.car_type_filter = nv;
        this.getData();
      }
    },
    updateList(nv, ov) {
      this.getData();
    },
  },
};
</script>
