<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.car_type`]="{ item }">
        {{ item.car_type == 'CT1' ? 'รย.1' : '' }}
        {{ item.car_type == 'CT2' ? 'รย.2' : '' }}
        {{ item.car_type == 'CT3' ? 'รย.3' : '' }}
        {{ item.car_type == 'CT4' ? 'รย.12' : '' }}
        {{ item.car_type == 'CT13' ? 'รย.17' : '' }}
        {{ item.car_type == 'CT5' ? 'รย.13' : '' }}
        {{ item.car_type == 'CT14' ? 'รย.15' : '' }}
        {{ item.car_type == 'CT6' ? 'ขส.10' : '' }}
        {{ item.car_type == 'CT7' ? 'ขส.30' : '' }}
        {{ item.car_type == 'CT8' ? 'ขส.40' : '' }}
        {{ item.car_type == 'CT9' ? 'ขส.70' : '' }}
        {{ item.car_type == 'CT10' ? 'ขส.80' : '' }}
        {{ item.car_type == 'CT11' ? 'รย.1EV' : '' }}
        {{ item.car_type == 'CT12' ? 'อื่น ๆ' : '' }}
      </template>
      <template v-slot:[`item.is_original_document`]="{ item }">
        <div v-if="item.is_original_document">ตัวจริง</div>
        <div v-else>สำเนา</div>
      </template>
      <template v-slot:[`item.tax_filling_date`]="{ item }">
        {{ formatDate(item.tax_data.tax_filling_date) }}
      </template>
      <template v-slot:[`item.extension_tax_fee`]="{ item }">
        {{ item.tax_data.extension_tax_fee | numeral('0,0.00') }}
      </template>

      <template v-slot:[`item.transportation_fee`]="{ item }">
        <!-- {{item.tax_data.transportation_fee | numeral('0,0.00')}} -->
        <v-text-field
          v-model="item.tax_data.transportation_fee"
          :value="item.tax_data.transportation_fee"
          @blur="saveTransportationFee(item)"
          type="number"
          outlined
          dense
          background-color="white"
          single-line
          hide-details
        >
        </v-text-field>
      </template>

      <template v-slot:[`item.tax_round`]="{ item }">
        {{ item.tax_data.tax_round }}
      </template>
      
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="grey" small outlined rounded @click="edit(item._id)">
          ดู
        </v-btn>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page.sync="filter.page"
          :itemsPerPage.sync="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="900" v-model="dialogForm">
      <InProgressForm :item="dialogFormData" @on-close="closeDialog" />
    </v-dialog>
  </div>
</template>

<script>
import InProgressForm from './InProgressForm';

import * as moment from 'moment';

export default {
  components: {
    InProgressForm,
  },
  props: ['search', 'taxRenewalRound'],
  data: () => ({
    dialogForm: false,
    dialogFormData: null,

    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    item_per_page: 10,
    list: [],
    headers: [
      { text: 'ลำดับ', value: '_id', filterable: false },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'ประเภท', value: 'car_type', filterable: false },
      { text: 'เอกสาร', value: 'is_original_document', filterable: false },
      { text: 'วันที่นัดรับ', value: 'tax_filling_date', filterable: false },
      { text: 'ค่าภาษี', value: 'extension_tax_fee', filterable: false },
      { text: 'ขนส่งเก็บ', value: 'transportation_fee', filterable: false },
      { text: 'รอบต่อภาษี', value: 'tax_round', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      { text: '', value: 'actions', filterable: false },
    ],
    isCar: true,
    filter: {
      branch_id: null,
      tracking_type: 0,
      tracking_status: 1,
      tax_renewal_round: '',
      car_type_filter: 0,
      startDate: moment().add(-12, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      item_per_page: 20,
      page: 1,
      start_with: '',
    },
  }),
  mounted() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_process`, body)
        .then((res) => {
          this.list = res.result.data;
          this.list.forEach((item, i) => {
            this.list[i].tax_data.transportation_fee =
              item.tax_data.transportation_fee == 0
                ? item.tax_data.extension_tax_fee
                : item.tax_data.transportation_fee;
          });
          this.pageCount = res.result.total_page || 1;
          this.$emit('input', res.result.total_tax);
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },

    openDialog() {
      this.dialogForm = true;
    },
    closeDialog() {
      this.dialogForm = false;
      this.getData();
    },

    async edit(id) {
      this.dialogForm = true;
      let body = {
        token: this.$jwt.sign({ process_id: id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_by_id`, body)
        .then((res) => {
          this.dialogFormData = res.result;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async saveTransportationFee(item) {
      let payload = {
        process_id: item._id,
        tax_data: {
          transportation_fee: parseFloat(item.tax_data.transportation_fee),
        },
        insurance_data: {},
        transport_data: {},
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/edit_process`, body)
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
  },

  watch: {
    search(newval, oldval) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (newval != oldval) {
          this.filter.start_with = newval;
          this.getData();
        }
      }, 1000);
    },
    taxRenewalRound(newval, oldval) {
      if (newval != oldval) {
        this.filter.tax_renewal_round = newval;
        this.getData();
      }
    },
  },
};
</script>
