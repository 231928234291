<template>
  <div>
    <v-data-table
      :headers="sms_setting.sms_service ? headers_with_sms : headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:[`item.parcel_delivery`]="{ item }">
        <v-icon v-if="item.parcel_delivery">mdi-check</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </template>

      <template v-slot:[`item.transportation_product`]="{ item }">
        <v-icon v-if="item.tax_data.transportation_product.length > 0">
          mdi-check
        </v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </template>

      <!-- <template v-slot:[`item.sms_job_done`]="{ item }">
        <v-btn
          v-show="false"
          v-if="!item.tax_data.sms_job_done"
          color="primary"
          small
          outlined
          @click="updateStatus(item._id, 0)"
        >
          กดเพื่อส่ง
        </v-btn>
        <v-btn
          v-show="false"
          v-if="item.tax_data.sms_job_done"
          color="primary"
          small
        >
          ส่งแล้ว
        </v-btn>
      </template> -->
      <template v-slot:[`item.tel_alert`]="{ item }">
        <div v-if="item.tax_data.is_call_alert">
          <v-btn
            v-if="item.tax_data.tel_alert == '0'"
            color="error"
            small
            outlined
            @click="updateStatus(item._id, 1)"
          >
            ต้องการให้โทร
          </v-btn>
          <v-btn v-else color="primary" small @click="updateStatus(item._id, 1)">
            โทรแล้ว {{ item.tax_data.tel_alert }} ครั้ง
          </v-btn>
        </div>
        <div v-if="!item.tax_data.is_call_alert">
          <v-btn color="primary" small outlined>ไม่ต้อง</v-btn>
        </div>
      </template>

      <template v-slot:[`item.pick_up_date`]="{ item }">
        <v-btn
          v-if="item.tracking_status != 3"
          color="success"
          small
          outlined
          @click="openReceivedDialog(item._id)"
        >
          รับเล่มคลิก
        </v-btn>
        <v-btn v-else color="success" small>รับเล่มแล้ว</v-btn>
      </template>

      <template v-slot:[`item.margin`]="{ item }">
        <div class="red--text">{{ item.tax_data.margin }}</div>
      </template>

      <template v-slot:[`item.margin_minus`]="{ item }">
        <div v-if="item.margin_minus" class="red--text text-center">
          {{ item.margin_minus | numeral('0,0.00') }}
        </div>
        <div v-else class="text-center">-</div>
      </template>

      <template v-slot:[`item.margin_plus`]="{ item }">
        <div v-if="item.margin_plus" class="success--text text-center">
          {{ item.margin_plus | numeral('0,0.00') }}
        </div>
        <div v-else class="text-center">-</div>
      </template>

      <template v-slot:[`item.customer_phone_number`]="{ item }">
          {{ phoneNumberFormat(item.customer_phone_number) }}
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page.sync="filter.page"
          :itemsPerPage.sync="filter.item_per_page"
          :length.sync="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="800" v-model="dialogForm">
      <CompleteForm
        :item="dialogFormData"
        @onClose="(dialogForm = false), getData()"
      />
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      max-width="800"
      v-model="dialogReceivedForm"
    >
      <ReceivedForm
        :item="dialogReceivedFormData"
        @onClose="(dialogReceivedForm = false), getData()"
      />
    </v-dialog>
  </div>
</template>

<script>
import CompleteForm from './CompleteForm';
import ReceivedForm from './ReceivedForm';
import * as moment from 'moment';

export default {
  components: {
    CompleteForm,
    ReceivedForm,
  },
  props: ['search'],
  data: () => ({
    sms_job_done: false,
    dialogForm: false,
    dialogFormData: null,
    dialogReceivedForm: false,
    dialogReceivedFormData: null,

    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    item_per_page: 10,
    list: [],
    sms_setting: {},
    item: {
      process_array: [],
    },
    headers: [
      { text: 'ลำดับ', value: '_id', filterable: false },
      { text: 'วันที่', value: 'date', filterable: false },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'ส่งพัสดุ', value: 'parcel_delivery', filterable: false },
      { text: 'งานขนส่ง', value: 'transportation_product', filterable: false },
      // { text: 'SMS', value: 'sms_job_done', filterable: false },
      { text: 'โทรแจ้งลูกค้า', value: 'tel_alert', filterable: false },
      { text: 'รับเล่ม', value: 'pick_up_date', filterable: false },
      { text: 'เบอร์โทร', value: 'customer_phone_number', filterable: false },
      { text: 'เก็บเพิ่ม', value: 'margin_minus', filterable: false },
      { text: 'คืนเงิน', value: 'margin_plus', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      { text: '', value: 'actions', filterable: false },
      // {
      //   text: 'สถานะ SMS',
      //   value: 'status',
      //   filterable: false,
      // },
    ],
    headers_with_sms: [
      { text: 'ลำดับ', value: '_id', filterable: false },
      { text: 'วันที่', value: 'date', filterable: false },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'ส่งพัสดุ', value: 'parcel_delivery', filterable: false },
      { text: 'งานขนส่ง', value: 'transportation_product', filterable: false },
      // { text: 'SMS', value: 'sms_job_done', filterable: false },
      { text: 'โทรแจ้งลูกค้า', value: 'tel_alert', filterable: false },
      { text: 'รับเล่ม', value: 'pick_up_date', filterable: false },
      { text: 'เบอร์โทร', value: 'customer_phone_number', filterable: false },
      { text: 'เก็บเพิ่ม', value: 'margin_minus', filterable: false },
      { text: 'คืนเงิน', value: 'margin_plus', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      { text: '', value: 'actions', filterable: false },
      {
        text: 'สถานะ SMS',
        value: 'status',
        filterable: false,
      },
    ],
    isCar: true,
    showPhoneNumber: false,
    filter: {
      branch_id: null,
      tracking_type: 0,
      tracking_status: 2,
      tax_renewal_round: '',
      car_type_filter: 0,
      item_per_page: 10,
      page: 1,
      start_with: '',
    },
  }),
  async mounted() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    await this.getSmsSetting();
    await this.getData();
    await this.getShowPhoneNumber();
  },
  methods: {
    async getSmsSetting() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_sms_setting`, body)
        .then(async (res) => {
          this.sms_setting = res.result;
          console.log(this.sms_setting)
        });
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_process`, body)
        .then(async (res) => {
          this.list = this.prepareProcessArray(res.result.data);

          if(this.sms_setting.sms_tax_extension){
            await this.getStatus();
          }
          this.pageCount = res.result.total_page || 1;
          this.totalTax = res.result.total_tax;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    getByid() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.filter.branch_id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.api_key = res.result.sms_setting.api_key;
          this.secret_key = res.result.sms_setting.secret_key;
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    async getShowPhoneNumber() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
        };

        this.show_phone_number = this.$store.state.group_permissions.show_phone_number;
        if (this.show_phone_number == 0) {
          this.showPhoneNumber = false;
        } else {
          this.showPhoneNumber = true;
        };
      } catch (error) {
        this.showPhoneNumber = true;
        console.error('Error in getShowPhoneNumber:', error);
      };
    }, 
    phoneNumberFormat(val) {
      if (val) {
        if (!this.showPhoneNumber) return 'XXX-XXXXXXX';
        return val.substring(0, 3) + '-' + val.substring(3, val.length);
      } else return val;
    },
    async getStatus() {
      const statusMap = {
        SUCCESS: 'ส่งสำเร็จ',
        FAILED: 'ล้มเหลว',
        PROCESSING: 'กำลังส่ง',
        EXPIRED: 'ล้มเหลว',
        BLOCKED: 'ลูกค้าบล็อก SMS'
      };

      // Function to introduce delay
      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        
        if (!item.tax_data.sms_transaction_id) {
          item.status = 'ไม่เชื่อมต่อ';
          continue; // Skip to the next item
        }
        
        item.status = ''; // Reset status for processing

        // Introduce a delay to avoid hitting rate limits
        await delay(200); // Wait for 1 second

        try {
          const response = await fetch('https://portal-otp.smsmkt.com/api/get-delivery-report', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              api_key: this.sms_setting.api_key,
              secret_key: this.sms_setting.secret_key,
            },
            body: JSON.stringify({
              transaction_id: item.tax_data.sms_transaction_id,
            }),
          });

          // Check if response is okay
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();

          if (data.result && data.result.length > 0) {
            const status = data.result[0].status;
            item.status = statusMap[status] || 'สถานะไม่รู้จัก'; // Default to 'Unknown Status' if status is not in map
          } else {
            item.status = 'ข้อมูลไม่ถูกต้อง';
          }
          
        } catch (error) {
          console.error('Error fetching status:', error);
          item.status = 'ข้อผิดพลาดในการดึงข้อมูล';
        }
      }
    },
    formatDate(date) {
      if (!date) return '';
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },

    openDialog() {
      this.dialogForm = true;
    },
    closeDialog() {
      this.dialogForm = false;
      this.getData();
    },

    async openReceivedDialog(id) {
      let body = {
        token: this.$jwt.sign({ process_id: id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_by_id`, body)
        .then((res) => {
          this.dialogFormData = res.result;
          this.dialogForm = true;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },

    cancel() {},
    prepareProcessArray(process_array) {
      let list = [];
      if (
        process_array &&
        process_array.length &&
        typeof process_array === 'object'
      ) {
        for (var i = 0; i < process_array.length; i++) {
          var o = Object.assign({}, process_array[i]);
          o.tax_data.is_call_alert =
            'is_call_alert' in o.tax_data ? o.tax_data.is_call_alert : false;
          list.push(o);
        }
      }
      return list;
    },
    async updateStatus(id, btnAction, value) {
      // btnAction => 0 = sms, 1 = call
      if (btnAction == 0) {
        console.log('sms');
        this.loading = true;
        await this.$axios
          .post(`${this.$baseUrl}/process/get_by_id`, {
            token: this.$jwt.sign({ process_id: id }, this.$privateKey, {
              noTimestamp: true,
            }),
          })
          .then(async (res) => {
            let oldData = res.result;
            oldData.tax_data.sms_job_done = true;

            // fill old data to edit payload
            let payload = {
              process_id: id,
              tax_data: oldData.tax_data,
              insurance_data: oldData.insurance_data,
              transport_data: oldData.transport_data,
              is_succes: oldData.is_success,
              reason_on_fail: oldData.reason_on_fail,
              reciever_name: oldData.reciever_name,
              note: oldData.note,
            };
            await this.$axios
              .post(`${this.$baseUrl}/process/edit_process`, {
                token: this.$jwt.sign(payload, this.$privateKey, {
                  noTimestamp: true,
                }),
              })
              .then((res) => {
                this.getData();
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          })
          .catch((err) => {
            console.log('err', err);
            this.$alertServerError({ title: err.error_message });
          });
        this.loading = false;
      } else if (btnAction == 1) {
        console.log('call');
        this.loading = true;
        // get old data
        await this.$axios
          .post(`${this.$baseUrl}/process/get_by_id`, {
            token: this.$jwt.sign({ process_id: id }, this.$privateKey, {
              noTimestamp: true,
            }),
          })
          .then(async (res) => {
            let oldData = res.result;
            oldData.tax_data.tel_alert =
              oldData.tax_data.tel_alert < 3
                ? oldData.tax_data.tel_alert + 1
                : 0;
            // fill old data to edit payload
            let payload = {
              process_id: id,
              tax_data: oldData.tax_data,
              insurance_data: oldData.insurance_data,
              transport_data: oldData.transport_data,
              is_succes: oldData.is_success,
              reason_on_fail: oldData.reason_on_fail,
              reciever_name: oldData.reciever_name,
              note: oldData.note,
            };
            await this.$axios
              .post(`${this.$baseUrl}/process/edit_process`, {
                token: this.$jwt.sign(payload, this.$privateKey, {
                  noTimestamp: true,
                }),
              })
              .then((res) => {
                this.getData();
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          })
          .catch((err) => {
            console.log('err', err);
            this.$alertServerError({ title: err.error_message });
          });
        this.loading = false;
      }
    },
  },
  computed: {},
  watch: {
    status(nv, ov) {
      if (nv != ov) {
        this.item.start_with = nv;
        this.getData();
      }
    },
    search(nv, ov) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (nv != ov) {
          this.filter.start_with = nv;
          this.getData();
        }
      }, 1000);
    },
  },
};
</script>
