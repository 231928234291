<template>
    <div>
      <v-data-table
        class="elevation-2"
        :headers="headers"
        :items="list"
        :loading="loading"
        sort-by="calories"
        :loading-text="$table_loading_text"
        :page.sync="page"
        :items-per-page="filter.item_per_page"
        hide-default-footer
      
      >
        <template v-slot:[`item._id`]="{ item }">
          {{list.map(function(x) {return x._id; }).indexOf(item._id)+1+(filter.item_per_page*(filter.page-1))}}
        </template>
        
        <template v-slot:[`item.pick_up_date`]="{ item }">
          {{ formatDate(item.pick_up_date) }}
        </template>

        <template v-slot:[`item.pick_up_time`]="{ item }">
          {{item.pick_up_time }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="grey" small outlined rounded @click="edit(item._id)">ดู</v-btn>
        </template>
 
        <template v-slot:footer>
          <table-pagination-custom
            :page="filter.page" 
            :itemsPerPage="filter.item_per_page" 
            :length="pageCount"
            @change-page="onChangePage"
            @change-items-per-page="onChangeitemsPerPage"
          />
        </template>

      </v-data-table>

      <v-dialog persistent scrollable max-width="600" v-model="dialogForm">
        <ReceivedForm :item="dialogFormData" @onClose="dialogForm = false"/>
      </v-dialog>

    </div>

</template>

<script>
import ReceivedForm from "./ReceivedForm";

import * as moment from "moment";

  export default {
    components: {
      ReceivedForm,
    },
    props: ['search'],
    data: () => ({
      dialogForm: false,
      dialogFormData: null,

      selected: [],
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      list: [],
      headers: [
        { text: 'ลำดับ', value: '_id', filterable: false},
        { text: 'วันที่รับเล่ม', value: 'pick_up_date', filterable: false },
        { text: 'เวลา', value: 'pick_up_time', filterable: false },
        { text: 'ทะเบียน', value: 'car_plate', filterable: false },
        { text: 'จังหวัด', value: 'province', filterable: false },
        { text: 'โน๊ต', value: 'note', filterable: false },
        { text: 'ชื่อผู้รับเอกสาร', value: 'receiver_name', filterable: false },
        { text: '', value: 'actions', filterable: false },
      ],

      isCar: true,
      filter: {
        branch_id: null,
        tracking_type: 0,
        tracking_status: 3,
        tax_renewal_round: '',
        car_type_filter: 0,
        item_per_page: 10,
        page: 1,
        start_with: ''
      }
    }),
    mounted () {
      this.filter.branch_id = this.$store.state.selected_branch._id;
      this.getData();
    },
    methods: {
      async getData () {
        this.loading = false;
        let body = {
          token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/process/get_process`, body)
          .then((res) =>{
            this.list = res.result.data;
            this.pageCount = res.result.total_page || 1;
          })
          .catch((err) => {
            console.log('err',err);
            this.$alertServerError({ title : err.error_message });
          });
        this.loading = false;
      },
   formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
      onChangePage(page){
        this.filter.page = page
        this.getData()
      },

      onChangeitemsPerPage(number){
        this.filter.item_per_page = number
        this.onChangePage(1)
      },

      openDialog() {
        this.dialogForm = true;
      },
      closeDialog() {
        this.dialogForm = false;
        this.getData()
      },

      async edit(id) {
        this.dialogForm = true;
        let body = {
          token: this.$jwt.sign({ process_id: id }, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/process/get_by_id`, body)
          .then((res) =>{
            this.dialogFormData = res.result;
        })
        .catch((err) => {
            console.log('err',err);
            this.$alertServerError({ title : err.error_message });
        });
      },

      cancel() {

      },
    },
    watch: {
      search(nv, ov){
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          if(nv != ov){
            this.filter.start_with = nv;
            this.getData();
          }
        }, 1000);
      },
      dialogForm: function (newVal, oldVal) {
        if(!newVal){
          this.getData()
          this.dialogFormData = null
          this.oldData = null
        }
      },
    }
  }
</script>